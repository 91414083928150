import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Internet = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`ネットワーク対応精算機 | ${title}`}</title>
        <meta
          name="description"
          content="インターネット接続に対応した、新世代の精算機。ICクレジットカード、電子マネー、バーコード決済、アプリ精算、法人カードなど各種キャッシュレス決済に対応。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>ネットワーク対応精算機</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>ネットワーク対応精算機</li>
        </div>
      </div>

      <div className="main-wrapper internet">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>ネットワーク対応精算機 ITC-LXN</h2>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-network.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              インターネット接続に対応した、新世代の精算機。
              <br />
              ICクレジットカード、電子マネー、バーコード決済、アプリ精算、法人カードなど
              <br />
              各種キャッシュレス決済に対応。
              <br />
            </h4>

            <p>
              インターネット接続により、これまでにない機能・操作を実現。次代の駐車場管理システムを先取る、スマートステーションです。
            </p>

            <h5>ここがアイテック！</h5>

            <div className="summary">
              <ul>
                <li>
                  インターネットを活用した、グローバル・ネットワークシステムに対応。
                </li>
                <li>法人カードやサービス券にも標準対応。</li>
                <li>
                  ICクレジット、電子マネー、コード決済でのキャッシュレス化。
                </li>
                <li>鍵＋暗証番号による二重ロックで、安心の防犯構造。</li>
                <li>省スペースで低コスト、小・中規模の駐車場に最適。</li>
                <li>前払い式精算機としても利用可能。</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>ネットワーク対応精算機ITC-LXN</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <div className="left-cont">
              <p>
                グローバル・ネットワークシステムに対応
                <br />
                法人カードが使えるリーダー標準装備
                <br />
                省スペースに対応したスマートでシンプルな外観
                <br />
                鍵と暗証番号による電子ロック機構の二重ロック
                <br />
                防犯性にすぐれた二重構造の筺体
                <br />
                自由に料金体系を設定
                <br />
                ワンタッチ操作でカンタン集金作業
              </p>

              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/service-internet-01.webp"
                />
                <img
                  className="sp-only internet-01"
                  src="/assets/images/common/service-internet-01.png"
                  alt=""
                />
              </picture>

              <h5>グローバル・ネットワークシステムとは</h5>

              <div className="summary">
                インターネットを使って、駐車場の精算機とデータセンターのホストコンピューターを接続。お客様にキャッシュレスで駐車場を利用いただくとともに、ご利用状況などを随時記録し、さまざまな特典サービスを提供する新世代駐車場管理システム。運営会社をまたいだQT-net加盟駐車場を結び、ワイドなサービスを展開します。
              </div>
            </div>

            <div className="right-cont">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/service-internet-01.webp"
                />
                <img
                  src="/assets/images/common/service-internet-01.png"
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>ネットワーク対応精算機標準仕様</h3>
          </div>
        </div>

        <div className="row">
          <div className="container spec">
            <table>
              <tbody>
                <tr>
                  <th>外形寸法</th>
                  <td>
                    H1548mm×W426mm×
                    <br className="sp-only" />
                    D465mm（突起部含む）
                  </td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約85kg</td>
                </tr>
                <tr>
                  <th>管理台数</th>
                  <td>車・バイク 最大60台、自転車 最大240台</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>AC100V　50/60Hz</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>60W（AC100V）</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度　0℃～45℃　湿度　結露しないこと</td>
                </tr>
                <tr>
                  <th>対応貨幣</th>
                  <td>10円・50円・100円・500円硬貨　1000円紙幣</td>
                </tr>
                <tr>
                  <th>釣銭払出</th>
                  <td>循環式</td>
                </tr>
                <tr>
                  <th>領収書発行</th>
                  <td>
                    約800枚印字可能　領収書の駐車場名及び会社名はアイテックよりオンラインで設定
                  </td>
                </tr>
                <tr>
                  <th>料金設定</th>
                  <td>
                    平日・土・日・祝日　それぞれの曜日に対し各3段階設定可能
                  </td>
                </tr>
                <tr>
                  <th>表示部</th>
                  <td>全角8文字2行表示</td>
                </tr>
                <tr>
                  <th>カードリーダー</th>
                  <td>バーコードリーダー（サービス券/法人カード）</td>
                </tr>
                <tr>
                  <th>外部通信機能</th>
                  <td>インターネット接続</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Internet
